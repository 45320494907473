import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import theme from '../../styles/Theme';

const useStyles = makeStyles({
  progressBar: {
    color: theme.palette.boksnokPurple,
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ progress }) {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(progress);
  }, [progress]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={value} className={classes.progressBar} />
    </Box>
  );
}
