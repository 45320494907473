import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import TopBar from '../components/GeneralLayout/TopBar';
import { postUsername } from '../api/coCreatorService';
import Dialog from '../components/MainDialog';

export default function Home() {
  const [id, setId] = useState('');

  const { username } = useParams();

  useEffect(() => {
    const postUser = async () => {
      const res = await postUsername(username);

      if (res) {
        setId(res.id);
      }
    };

    if (username) {
      postUser();
    }
  }, [username]);

  return (
    <div>
      <TopBar />
      <Dialog username={username} id={id} />
    </div>
  );
}
