import './App.css';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './styles/Theme';

function App() {
  return (
    <Switch>
      <Route path="/:username">
        <ThemeProvider theme={theme}>
          <Home />
        </ThemeProvider>
      </Route>
    </Switch>
  );
}

export default App;
