import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    boksnokPurple: '#4d16b2',
  },
  typography: {
    fontSize: 16,
    p: {
      fontSize: '16px',
      fontFamily: '"Karla", sans-serif',
    },
    h1: {
      fontFamily: '"Karla", sans-serif',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.3rem',
      fontFamily: '"Karla", sans-serif',
    },
    h3: {
      fontWeight: 700,
      fontSize: '2.2rem',
      fontFamily: '"Karla", sans-serif',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.75rem',
      fontFamily: '"Karla", sans-serif',
    },
    h5: {
      fontWeight: 500,
      fontFamily: '"Karla", sans-serif',
    },
    h6: {
      fontWeight: 500,
      fontFamily: '"Karla", sans-serif',
    },
  },
});

export default theme;
