import React from 'react';

import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  h1: {
    color: '#4d16b2',
    fontSize: '24px !important',
    fontFamily: theme.typography.h1,
    textAlign: 'center',
    marginBottom: 8,
  },
  p: {
    color: 'grey',
    fontSize: '16px !important',
    fontFamily: theme.typography.p.fontFamily,
    textAlign: 'center',
  },
  container: {
    marginTop: 48,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleIcon: {
    fontSize: 42,
    marginBottom: 16,
    alignSelf: 'center',
  },
  circularProgress: {
    color: '#4d16b2',
    alignSelf: 'center',
    marginTop: '15%',
  },
  divider: { width: '85%', margin: '30px 0 0 0' },
  metadataGrid: {
    marginTop: '10px',
    textAlign: 'left',
  },
  metadataItem: {
    fontSize: 12,
    padding: '4px 0',
  },
  metadataLabel: {
    fontSize: 14,
    color: 'gray',
  },
  anotherBookContainer: {
    '& button': {
      float: 'right',
    },
    width: '100%',
    margin: '0 30px 30px 0',
  },
}));

export default function FormSuccessMessage({
  showSuccessMessage,
  circularProgress,
  metadata,
  filenames,
}) {
  const classes = useStyles();

  const handleSubmitAnother = () => {
    window.location.reload();
  }

  return (
    <Box
      sx={{
        width: 500,
        textAlign: 'center',
        padding: 15,
      }}
    >
      <div className={classes.container}>
        {showSuccessMessage && (
          <>
            <CheckCircleIcon className={classes.circleIcon} />
            <Typography className={classes.h1}>Thank you for submitting your book!</Typography>
            <Typography className={classes.p}>
              We'll review it and get back to you as soon as possible.
              <br />
              For further questions: <a href="mailto: publish@boksnok.se">publish@boksnok.se</a>
            </Typography>

            <Divider variant="middle" className={classes.divider} />

            <Grid container spacing={3}>
              <Grid item xs={1} />
              <Grid item xs={10} className={classes.metadataGrid}>
                <h4 style={{ marginBottom: 0 }}>Summary of your submission:</h4>
                <List>
                  {filenames.map((filename) => (
                    <ListItem key={filename}>
                      <AttachmentIcon />
                      <ListItemText primary={filename} style={{ padding: '0 10px' }} />
                    </ListItem>
                  ))}
                </List>
                <div className={classes.metadataItem}>
                  <span className={classes.metadataLabel}>Author:</span> {metadata.author || ''}
                </div>
                <div className={classes.metadataItem}>
                  <span className={classes.metadataLabel}>Title:</span> {metadata.title || ''}
                </div>
                <div className={classes.metadataItem}>
                  <span className={classes.metadataLabel}>Illustrator:</span>{' '}
                  {metadata.illustrator || ''}
                </div>
                <div className={classes.metadataItem}>
                  <span className={classes.metadataLabel}>Age group:</span>{' '}
                  {metadata.ageGroup || ''}
                </div>
                <div className={classes.metadataItem}>
                  <span className={classes.metadataLabel}>Description:</span>
                  <div>{metadata.summary || ''}</div>
                </div>
              </Grid>
              <Grid item xs={5} className={classes.metadataGrid}></Grid>

              <div className={classes.anotherBookContainer}>
                <Button variant="contained" color="primary" size="small" onClick={handleSubmitAnother}>
                  Submit another book
                </Button>
              </div>
            </Grid>
          </>
        )}
        {circularProgress && <CircularProgress className={classes.circularProgress} />}
      </div>
    </Box>
  );
}
