import { postRequest, patchRequest } from './apiUtils';
const { BASE_URL } = require('../config');

export const postUsername = async (username) => {
  try {
    const res = await postRequest(
      `${BASE_URL}/co-creation`,
      { username: username },
      {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const postBook = async (id, files, config) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('pdfs', file);
  });

  try {
    const res = await postRequest(`${BASE_URL}/co-creation/${id}/upload`, formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const updateBook = async (id, data) => {
  try {
    const res = await patchRequest(
      `${BASE_URL}/co-creation/${id}`,
      { metadata: data },
      {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};
