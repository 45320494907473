import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DropZone from './UploadBookForm/DropZone/DropZone';
import FormDialog from './UploadBookForm/FormDialog';
import FormSuccessMessage from './UploadBookForm/FormSuccessMessage';
import ProgressBar from './GeneralLayout/ProgressBar';
import UploadSnackBar from './GeneralLayout/SnackBar';

export default function MainDialog({ username, id }) {
  const [filenames, setFilenames] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [circularProgress, setCircularProgress] = useState(false);
  const [formDialogText, setFormDialogText] = useState(
    '...uploading book, in the meantime, please fill in some information about your book.'
  );
  const [progress, setProgress] = useState(0);
  const [showDropZone, setShowDropZone] = useState(true);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [snackBarState, setSnackBarState] = useState('');
  const [showFormSuccessDialog, setShowFormSuccessDialog] = useState(false);
  const [showFormSuccessMessage, setFormShowSuccessMessage] = useState(false);

  const handleShowDropZone = (value) => {
    setShowDropZone(value);
    if (!value) {
      setShowFormDialog(true);
    } else {
      setShowFormDialog(false);
    }
  };

  const handleFormResponse = (response, metadata) => {
    if (response?.status === 200) {
      setTimeout(function () {
        setCircularProgress(false);
        setFormShowSuccessMessage(true);
        setMetadata({ ...metadata });
      }, 2000);
    } else if (response?.status > 400) {
      setSnackBarState({
        state: 'error',
        message: 'File could not be uploaded, please try again.',
      });
      setShowFormDialog(true);
    }
  };

  const handleShowProgressBar = (value) => {
    setShowProgressBar(value);
  };

  const handleUploadRequest = (response, files) => {
    if (response?.status === 200) {
      handleShowProgressBar(false);
      setSnackBarState({ state: 'success', message: 'File was successfully uploaded!' });
      setFormDialogText('Please fill in some information about your book.');
      setFilenames((files || []).map(file => file.path));
    } else if (response?.status > 400) {
      handleShowProgressBar(false);
      setSnackBarState({
        state: 'error',
        message: 'File could not be uploaded, please try again.',
      });
      handleShowDropZone(true);
    }
  };

  return (
    <div>
      <Dialog open={true}>
        <DropZone
          id={id}
          username={username}
          showDropZone={showDropZone}
          handleShowDropZone={handleShowDropZone}
          handleShowProgressBar={handleShowProgressBar}
          handleUploadRequest={handleUploadRequest}
          setProgress={setProgress}
        />
        <FormDialog
          id={id}
          showFormDialog={showFormDialog}
          handleFormResponse={handleFormResponse}
          formDialogText={formDialogText}
          setShowFormDialog={setShowFormDialog}
          setCircularProgress={setCircularProgress}
          setShowFormSuccessDialog={setShowFormSuccessDialog}
        />
        {showProgressBar && <ProgressBar progress={progress} />}
        {showFormSuccessDialog && (
          <FormSuccessMessage
            filenames={filenames}
            metadata={metadata}
            showSuccessMessage={showFormSuccessMessage}
            circularProgress={circularProgress}
          />
        )}
      </Dialog>
      {snackBarState && (
        <UploadSnackBar severity={snackBarState?.state} text={snackBarState?.message} />
      )}
    </div>
  );
}
