import axios from 'axios';

export const postRequest = (url, data, config = {}) => {
  try {
    return axios.post(url, data, { ...config });
  } catch (e) {
    console.log(e);
  }
};

export const patchRequest = (url, data, config = {}) => {
  try {
    return axios.patch(url, data, { ...config });
  } catch (e) {
    console.log(e);
  }
};
