import React from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import { postBook } from '../../../api/coCreatorService';
import '../DropZone/DropZone.css';

export default function DropZone({
  id,
  username,
  handleShowDropZone,
  showDropZone,
  handleShowProgressBar,
  handleUploadRequest,
  setProgress,
}) {
  const config = {
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      setProgress(progress);
    },
  };

  return (
    <DropzoneDialog
      dialogTitle={`Welcome ${username}! Upload your book here.`}
      dropzoneText={'Drag and drop or click to select file'}
      cancelButtonText={'cancel'}
      submitButtonText={'submit'}
      acceptedFiles={['application/pdf']}
      open={showDropZone}
      showAlerts={false}
      maxFileSize={500 * 1024 * 1024}
      onSave={async (files) => {
        handleShowDropZone(false);
        handleShowProgressBar(true);
        let response = await postBook(id, files, config);
        handleUploadRequest(response, files);
      }}
      showFileNamesInPreview={true}
    />
  );
}
