import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { updateBook } from '../../api/coCreatorService';

export default function FormDialog({
  formDialogText,
  handleFormResponse,
  id,
  setCircularProgress,
  showFormDialog,
  setShowFormSuccessDialog,
  setShowFormDialog,
}) {
  const defaultValues = {
    title: '',
    author: '',
    illustrator: '',
    ageGroup: '',
  };

  const [formValues, setFormValues] = useState(defaultValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowFormDialog(false);
    setShowFormSuccessDialog(true);
    setCircularProgress(true);
    const res = await updateBook(id, formValues);
    handleFormResponse(res, formValues);
  };

  return (
    <>
      {showFormDialog && (
        <>
          <DialogTitle>{formDialogText}</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
            >
              <TextField
                required
                id="author-outlined-required"
                name="author"
                label="Author"
                onChange={handleInputChange}
              />
              <TextField
                required
                id="title-outlined-required"
                name="title"
                label="Title"
                onChange={handleInputChange}
              />
              <TextField
                id="illustrator-outlined-required"
                label="Illustrator"
                name="illustrator"
                onChange={handleInputChange}
              />
              <TextField
                id="select-outlined-required"
                label="Age group"
                select
                name="ageGroup"
                value={formValues.ageGroup}
                onChange={handleInputChange}
              >
                <MenuItem value={`0-3`}>0-3 years</MenuItem>
                <MenuItem value={`3-6`}>3-6 years</MenuItem>
                <MenuItem value={`6-9`}>6-9 years</MenuItem>
              </TextField>
            </Box>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '50ch' },
              }}
            >
              <TextField
                id="description-outlined-required"
                label="Description"
                multiline
                name="summary"
                onChange={handleInputChange}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
}
